<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
  <!-- <NavbarViewVue /> -->
  <router-view></router-view>

  <!-- <HomeLayout /> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
// import NavbarViewVue from "./components/NavbarView.vue";
// import HomeLayout from "./components/HomeView.vue";

export default {
  name: "App",
  components: {
    // NavbarViewVue,
    // HomeLayout,
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
